<template>
  <b-container>
    <b-row>
      <b-col>
        <h4 class="page-title">Your Registered Businesses</h4>
        <business-list :businesses="businesses"></business-list>
      </b-col>
    </b-row>
    <app-spinner ref="spinner"></app-spinner>
  </b-container>
</template>

<script>
  import BusinessService from '@/services/BusinessService'
  import BusinessList from '@/components/BusinessList.vue'

  export default {
    name: 'BusinessIndex',

    components: {
      BusinessList
    },

    data () {
      return {
        businesses: []
      }
    },

    methods: {
      async loadBusinesss () {
        this.$refs.spinner.show()

        try {
          this.businesses = await BusinessService.findAll()
        } catch (error) {
          console.log(error.response.data.message)
          this.$refs.toast.error(error.response.data.message)
        }

        this.$refs.spinner.hide()
      }
    },

    mounted () {
      this.loadBusinesss()
    }
  }
</script>

<style>
</style>
