<template>
  <div>
    <b-card>
      <b-row class="mb-3">
        <!-- Filtering Feature -->
        <b-col cols="8" lg="3">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Type to Search"
            size="sm"
          ></b-form-input>
        </b-col>
        <b-col cols="4" lg="9" class="d-flex justify-content-end">
          <router-link
            class="btn btn-outline-primary btn-sm mr-2"
            :to="{ name: 'BusinessRenew' }"
            >Renew</router-link
          >
          <router-link
            class="btn btn-primary btn-sm"
            :to="{ name: 'PermitNew' }"
            >New</router-link
          >
        </b-col>
      </b-row>
      <b-table
        :items="businesses"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        responsive
        stacked="md"
        tbody-tr-class="cardOnMobile"
      >
        <!-- Render date column -->
        <template #cell(dateRegistered)="data">
          {{ renderDate(data.value) }}
        </template>

        <!-- Render isActive column -->
        <template #cell(isActive)="data">
          {{ renderIsActive(data.value) }}
        </template>

        <!-- Render actions link -->
        <template #cell(actions)="data">
          <button class="btn p-0" @click="toDetail(data.item.id)">
            <i class="bi-arrow-right text-info"></i>
          </button>
        </template>
      </b-table>
      <b-row class="justify-content-end">
        <!-- Per page select -->
        <b-col lg="2" class="d-flex align-items-center pr-0">
          <p class="mb-0">Show</p>
          <b-form-select
            class="mx-1"
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
          <p class="mb-0">entries</p>
        </b-col>
        <b-col lg="2"></b-col>
        <b-col lg="8">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  export default {
    name: 'BusinessList',

    props: {
      businesses: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        fields: [
          { key: 'name', sortable: true },
          { key: 'ownershipKind', label: 'Ownership', sortable: true },
          { key: 'dateRegistered', label: 'Registered', sortable: true },
          { key: 'isActive', label: 'Status', sortable: true },
          { key: 'actions', label: '', sortable: false }
        ],
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: []
      }
    },

    methods: {
      renderIsActive (bool) {
        return bool ? 'Active' : 'Inactive'
      },

      renderDate (dateString) {
        return dateString.substr(0, 10)
      },

      toDetail (id) {
        this.$router.push({ name: 'BusinessEdit', params: { id } })
      }
    },

    computed: {
      totalRows () {
        return this.businesses.length
      }
    }
  }
</script>

<style>
</style>
